<template>
  <div>
    <!-- <Header></Header> -->
    <div
      class="banner"
      style="
        width: 100%;
        height: auto;
        text-align: center;
        margin-bottom: 40px;
      "
    >
    <img :src="Src_" alt="" style="width:100%; height:100%;">
    </div>
    <div class="main">
      <div v-if="seen2">
        <div class="solve head-label" style=" padding: 50px;">
            <div style="text-align: center; font-size:34px; " class="font_size_24">
              健康体检类
            </div>
        </div>
        <!-- 盒子 -->
        <div class="border_parent">
            <el-row :gutter="10">
                <el-col :xs="12" :sm="12" :md="6">
                    <div class="border">
                        <div class="border_img_parent">
                            <img src="../assets/icon/mdt.png" class="img_border"/>
                        </div>
                        <div class="border_title">医疗机构体检</div>
                        <div class="border_content_one" style="padding:0 30px;">联系医疗机构，设计科学的体检套餐，安排专业健康体检。</div>
                        <div class="border_content_two"></div>
                    </div>
                </el-col>
                <el-col :xs="12" :sm="12" :md="6">
                    <div class="border">
                        <div class="border_img_parent">
                            <img src="../assets/icon/体检.png" class="img_border"/>
                        </div>
                        <div class="border_title">体检报告解读</div>
                        <div class="border_content_one" style="padding:0 30px;">根据需求联系医学专家提供专业的体检报告解读。</div>
                        <div class="border_content_two"></div>
                    </div>
                </el-col>
                <el-col :xs="12" :sm="12"  :md="6">
                    <div class="border" style="margin-right: 0">
                        <div class="border_img_parent">
                            <img src="../assets/icon/基因.png" class="img_border"/>
                        </div>
                        <div class="border_title">基因检测</div>
                        <div class="border_content_one" style="padding:0 30px;">为客户提供多种类型的基因检测产品和服务，包括高危肿瘤基因检测、HPV检测、结直肠癌早期筛查、重大慢性疾病检测、儿童生长发育基因检测、儿童天赋基因检测等。</div>
                        <div class="border_content_two"></div>
                    </div>
                </el-col>
                <el-col :xs="12" :sm="12"  :md="6">
                    <div class="border" style="margin-right: 0">
                        <div class="border_img_parent">
                            <img src="../assets/icon/档案.png" class="img_border"/>
                        </div>
                        <div class="border_title">健康档案</div>
                        <div class="border_content_one" style="padding:0 30px;">按照客户授权，建立电子健康档案，客户可在移动端随时查看档案。</div>
                        <div class="border_content_two"></div>
                    </div>
                </el-col>
                <el-col :xs="12" :sm="12"  :md="6">
                    <div class="border" style="margin-right: 0">
                        <div class="border_img_parent">
                            <img src="../assets/icon/评估.png" class="img_border"/>
                        </div>
                        <div class="border_title">健康评估</div>
                        <div class="border_content_one" style="padding:0 30px;">运用先进的评估软件，根据客户健康档案及问卷调查，评估健康风险。包括：AI中医体质测评、癌症风险自查、体姿体态评估、亚健康评估等多种评估。</div>
                        <div class="border_content_two"></div>
                    </div>
                </el-col>
            </el-row>
        </div>

        <div class="solve head-label" style=" padding: 50px;">
            <div style="text-align: center; font-size:34px; " class="font_size_24">
              健康咨询类
            </div>
        </div>
        <!-- 盒子 -->
        <div class="border_parent">
            <el-row :gutter="10">
                <el-col :xs="12" :sm="12" :md="6">
                    <div class="border">
                        <div class="border_img_parent">
                            <img src="../assets/icon/在线问诊.png" class="img_border"/>
                        </div>
                        <div class="border_title">在线问诊</div>
                        <div class="border_content_one" style="padding:0 30px;">专业医生在线快速解答健康问题，包括图文、视频等多种形式，小病小痛线上随时咨询，客户的家庭医生，身边的健康助手。</div>
                        <div class="border_content_two"></div>
                    </div>
                </el-col>
                <el-col :xs="12" :sm="12" :md="6">
                    <div class="border">
                        <div class="border_img_parent">
                            <img src="../assets/icon/电话医生.png" class="img_border"/>
                        </div>
                        <div class="border_title">电话医生</div>
                        <div class="border_content_one" style="padding:0 30px;">为客户精心打造全流程贴心、温暖、便捷的电话医生直通服务，提供常见病症咨询。</div>
                        <div class="border_content_two"></div>
                    </div>
                </el-col>
                <el-col :xs="12" :sm="12"  :md="6">
                    <div class="border" style="margin-right: 0">
                        <div class="border_img_parent">
                            <img src="../assets/icon/心理咨询.png" class="img_border"/>
                        </div>
                        <div class="border_title">心理咨询</div>
                        <div class="border_content_one" style="padding:0 30px;">与专业心理咨询机构合作，可为客户提供7*24小时的电话心理咨询热线服务，专业心理咨询师为客户一对一解答各类心理问题，包括工作压力、情绪困扰、精神健康、人际关系、职业发展、恋爱、婚姻、亲子教育、家庭关系等。</div>
                        <div class="border_content_two"></div>
                    </div>
                </el-col>
            </el-row>
        </div>

        <div class="solve head-label" style=" padding: 50px;">
            <div style="text-align: center; font-size:34px; " class="font_size_24">
              健康促进类
            </div>
        </div>
        <!-- 盒子 -->
        <div class="border_parent">
            <el-row :gutter="10">
                <el-col :xs="12" :sm="12" :md="6">
                    <div class="border">
                        <div class="border_img_parent">
                            <img src="../assets/icon/健康讲座.png" class="img_border"/>
                        </div>
                        <div class="border_title">健康讲座</div>
                        <div class="border_content_one" style="padding:0 30px;">为关爱客户身心健康，进一步增强健康意识，提高身体素质，聘请知名医学专家、公共卫生专家、营养专家等健康领域专家，定期组织健康知识讲座。</div>
                        <div class="border_content_two"></div>
                    </div>
                </el-col>
                <el-col :xs="12" :sm="12" :md="6">
                    <div class="border">
                        <div class="border_img_parent">
                            <img src="../assets/icon/健康资讯.png" class="img_border"/>
                        </div>
                        <div class="border_title">健康资讯</div>
                        <div class="border_content_one" style="padding:0 30px;">通过多种渠道，多角度、全方位向客户传播权威健康资讯，包括：名医讲堂、健康视频、健康资讯、健康百科。</div>
                        <div class="border_content_two"></div>
                    </div>
                </el-col>
                <el-col :xs="12" :sm="12"  :md="6">
                    <div class="border" style="margin-right: 0">
                        <div class="border_img_parent">
                            <img src="../assets/icon/数据监测.png" class="img_border"/>
                        </div>
                        <div class="border_title">健康数据监测</div>
                        <div class="border_content_one" style="padding:0 30px;">通过穿戴式智能设备进行全面的健康风险筛查；对健康指标异常客户进行分级分类管理，开展定期指标监测和健康指导，如身高、体重、体脂含量、心率、血压、心率变异性、血糖、血脂、血氧、尿酸、心电等监测服务。</div>
                        <div class="border_content_two"></div>
                    </div>
                </el-col>
                <el-col :xs="12" :sm="12"  :md="6">
                    <div class="border" style="margin-right: 0">
                        <div class="border_img_parent">
                            <img src="../assets/icon/特色服务.png" class="img_border"/>
                        </div>
                        <div class="border_title">中医特色服务</div>
                        <div class="border_content_one" style="padding:0 30px;">专业的中医理疗师使用手法推拿、拔罐、刮痧及仪器理疗，缓解肩颈、腰椎以及其他部位身体不适，达到放松和治疗效果。</div>
                        <div class="border_content_two"></div>
                    </div>
                </el-col>
            </el-row>
        </div>

        <div class="solve head-label" style=" padding: 50px;">
            <div style="text-align: center; font-size:34px; " class="font_size_24">
              疾病预防类
            </div>
        </div>
        <!-- 盒子 -->
        <div class="border_parent">
            <el-row :gutter="10">
                <el-col :xs="12" :sm="12" :md="6">
                    <div class="border">
                        <div class="border_img_parent">
                            <img src="../assets/icon/齿科.png" class="img_border"/>
                        </div>
                        <div class="border_title">齿科服务</div>
                        <div class="border_content_one" style="padding:0 30px;">利用知名的齿科机构资源，为客户搭建方便、快捷的齿科保健服务平台，提供多种类型的齿科服务及套餐，服务网络遍布全国，协助客户合理选择齿科服务和齿科门店。</div>
                        <div class="border_content_two"></div>
                    </div>
                </el-col>
                <el-col :xs="12" :sm="12" :md="6">
                    <div class="border">
                        <div class="border_img_parent">
                            <img src="../assets/icon/眼科.png" class="img_border"/>
                        </div>
                        <div class="border_title">眼科服务</div>
                        <div class="border_content_one" style="padding:0 30px;">利用知名的眼科机构资源，为客户搭建方便、快捷的眼部保健服务平台，提供丰富、实用的眼科服务，包括：干眼症治疗体验套餐、青少年近视防控检查、糖尿病视网膜病变管理检查套餐等。服务网络覆盖全国绝大部分省市，协助客户合理选择眼科服务和门店。</div>
                        <div class="border_content_two"></div>
                    </div>
                </el-col>
                <el-col :xs="12" :sm="12"  :md="6">
                    <div class="border" style="margin-right: 0">
                        <div class="border_img_parent">
                            <img src="../assets/other/医生.jpg" class="img_border"/>
                        </div>
                        <div class="border_title">中医服务</div>
                        <div class="border_content_one" style="padding:0 30px;">发挥中医治未病优势，利用全国中医治未病科、中医门诊部/诊所资源，为客户搭建方便、快捷的中医治未病保健服务平台，服务网络遍布全国，协助客户合理选择中医服务和门店。</div>
                        <div class="border_content_two"></div>
                    </div>
                </el-col>
                <el-col :xs="12" :sm="12"  :md="6">
                    <div class="border" style="margin-right: 0">
                        <div class="border_img_parent">
                            <img src="../assets/icon/疫苗.png" class="img_border"/>
                        </div>
                        <div class="border_title">疫苗接种服务</div>
                        <div class="border_content_one" style="padding:0 30px;">协调安排社区卫生服务中心、民营医疗机构等为客户接种各类疫苗。</div>
                        <div class="border_content_two"></div>
                    </div>
                </el-col>
            </el-row>
        </div>

        <div class="solve head-label" style=" padding: 50px;">
            <div style="text-align: center; font-size:34px; " class="font_size_24">
              慢病管理类
            </div>
        </div>
        <!-- 盒子 -->
        <div class="border_parent">
            <el-row :gutter="10">
                <el-col :xs="12" :sm="12" :md="6">
                    <div class="border">
                        <div class="border_img_parent">
                            <img src="../assets/icon/健康干预.png" class="img_border"/>
                        </div>
                        <div class="border_title">健康干预服务</div>
                        <div class="border_content_one" style="padding:0 30px;">评估客户的阶段性健康监测指标，分析评估慢性病风险，针对性的开展饮食、运动及健康生活方式指导，进行个性化的健康干预及慢性病管理。</div>
                        <div class="border_content_two"></div>
                    </div>
                </el-col>
                <el-col :xs="12" :sm="12" :md="6">
                    <div class="border">
                        <div class="border_img_parent">
                            <img src="../assets/icon/家庭医生.png" class="img_border"/>
                        </div>
                        <div class="border_title">家庭医生服务</div>
                        <div class="border_content_one" style="padding:0 30px;">由签约医学专家作为客户的家庭医生，为客户及家人提供全面体检管理、日常健康咨询、慢性疾病管理、定期健康随访等健康管理服务，为客户全家人的健康保驾护航。</div>
                        <div class="border_content_two"></div>
                    </div>
                </el-col>
            </el-row>
        </div>

        <div class="solve head-label" style=" padding: 50px;">
            <div style="text-align: center; font-size:34px; " class="font_size_24">
              就医服务类
            </div>
        </div>
        <!-- 盒子 -->
        <div class="border_parent">
            <el-row :gutter="10">
                <el-col :xs="12" :sm="12" :md="6">
                    <div class="border">
                        <div class="border_img_parent">
                            <img src="../assets/icon/门诊预约.png" class="img_border"/>
                        </div>
                        <div class="border_title">门诊预约</div>
                        <div class="border_content_one" style="padding:0 30px;">为客户提供合作医院的专家门诊预约服务（副主任医师及以上），可以指定医院、科室，不能指定专家。</div>
                        <div class="border_content_two"></div>
                    </div>
                </el-col>
                <el-col :xs="12" :sm="12" :md="6">
                    <div class="border">
                        <div class="border_img_parent">
                            <img src="../assets/icon/陪诊.png" class="img_border"/>
                        </div>
                        <div class="border_title">全程陪诊</div>
                        <div class="border_content_one" style="padding:0 30px;">由专业陪诊人员提供全程陪同服务。陪诊人员带领客户至专家所在楼层、诊室，陪同等候，代客户完成检查化验排队、划价、取药、代取检查化验单、复印病历等事务，并提供相关健康信息。</div>
                        <div class="border_content_two"></div>
                    </div>
                </el-col>
                <el-col :xs="12" :sm="12"  :md="6">
                    <div class="border" style="margin-right: 0">
                        <div class="border_img_parent">
                            <img src="../assets/icon/住院协调.png" class="img_border"/>
                        </div>
                        <div class="border_title">住院协助</div>
                        <div class="border_content_one" style="padding:0 30px;">为客户提供住院联系安排，解决由于医疗信息不对称导致的住院难题。</div>
                        <div class="border_content_two"></div>
                    </div>
                </el-col>
                <el-col :xs="12" :sm="12"  :md="6">
                    <div class="border" style="margin-right: 0">
                        <div class="border_img_parent">
                            <img src="../assets/icon/手术安排.png" class="img_border"/>
                        </div>
                        <div class="border_title">手术安排</div>
                        <div class="border_content_one" style="padding:0 30px;">为客户提供手术预约安排，避免客户住院确诊之后长期无法手术的问题。</div>
                        <div class="border_content_two"></div>
                    </div>
                </el-col>
                <el-col :xs="12" :sm="12"  :md="6">
                    <div class="border" style="margin-right: 0">
                        <div class="border_img_parent">
                            <img src="../assets/icon/二次诊疗.png" class="img_border"/>
                        </div>
                        <div class="border_title">二次诊疗意见</div>
                        <div class="border_content_one" style="padding:0 30px;">提供合作医疗机构的专家第二诊疗意见服务，通过病理会诊、影像会诊等途径，由专家对客户的病情进行进一步分析，再次进行诊断或提出治疗意见。</div>
                        <div class="border_content_two"></div>
                    </div>
                </el-col>
                <el-col :xs="12" :sm="12"  :md="6">
                    <div class="border" style="margin-right: 0">
                        <div class="border_img_parent">
                            <img src="../assets/icon/远程医疗咨询.png" class="img_border"/>
                        </div>
                        <div class="border_title">远程医疗咨询</div>
                        <div class="border_content_one" style="padding:0 30px;">依托国内的公立三甲医院，通过高清远程问诊网络平台，运用高科技手段提供专业、客观、及时的远程咨询服务。</div>
                        <div class="border_content_two"></div>
                    </div>
                </el-col>
                <el-col :xs="12" :sm="12"  :md="6">
                    <div class="border" style="margin-right: 0">
                        <div class="border_img_parent">
                            <img src="../assets/icon/mdt.png" class="img_border"/>
                        </div>
                        <div class="border_title">多学科会诊MDT服务</div>
                        <div class="border_content_one" style="padding:0 30px;">整合多学科团队的医学专家，通过信息科技手段进行实时沟通，为肿瘤患者及其他重大疾病患者进行协作会诊，讨论制定治疗方案。</div>
                        <div class="border_content_two"></div>
                    </div>
                </el-col>
                <el-col :xs="12" :sm="12"  :md="6">
                    <div class="border" style="margin-right: 0">
                        <div class="border_img_parent">
                            <img src="../assets/icon/海外医疗.png" class="img_border"/>
                        </div>
                        <div class="border_title">海外医疗服务</div>
                        <div class="border_content_one" style="padding:0 30px;">根据客户的需求，帮助客户获取国际医疗资源，提供合作机构范围内专业、优质的海外健康体检套餐服务、海外医疗机构和医学专家诊疗服务、海外远程会诊服务等。</div>
                        <div class="border_content_two"></div>
                    </div>
                </el-col>
            </el-row>
        </div>

        <div class="solve head-label" style=" padding: 50px;">
            <div style="text-align: center; font-size:34px; " class="font_size_24">
              药品服务类
            </div>
        </div>
        <!-- 盒子 -->
        <div class="border_parent">
            <el-row :gutter="10">
                <el-col :xs="12" :sm="12" :md="6">
                    <div class="border">
                        <div class="border_img_parent">
                            <img src="../assets/icon/线上购药.png" class="img_border"/>
                        </div>
                        <div class="border_title">线上购药</div>
                        <div class="border_content_one" style="padding:0 30px;">利用线上购药服务网络资源，提供合作连锁药店在线购药服务。连锁品牌药店，质量有保障，药品齐全，核心城区28分钟送药到家。</div>
                        <div class="border_content_two"></div>
                    </div>
                </el-col>
                <el-col :xs="12" :sm="12" :md="6">
                    <div class="border">
                        <div class="border_img_parent">
                            <img src="../assets/icon/线下购药.png" class="img_border"/>
                        </div>
                        <div class="border_title">线下药店购药</div>
                        <div class="border_content_one" style="padding:0 30px;">客户可在我公司合作的线下药店购药，使用健康服务卡支付。提供附近药店查找及详细地址，支持提前电话咨询。</div>
                        <div class="border_content_two"></div>
                    </div>
                </el-col>
                <el-col :xs="12" :sm="12"  :md="6">
                    <div class="border" style="margin-right: 0">
                        <div class="border_img_parent">
                            <img src="../assets/icon/代购药品.png" class="img_border"/>
                        </div>
                        <div class="border_title">代购药品</div>
                        <div class="border_content_one" style="padding:0 30px;">为患有慢性疾病需要长期规律用药的客户，提供药品代购服务。</div>
                        <div class="border_content_two"></div>
                    </div>
                </el-col>
            </el-row>
        </div>

        <div class="solve head-label" style=" padding: 50px;">
            <div style="text-align: center; font-size:34px; " class="font_size_24">
              康复护理类
            </div>
        </div>
        <!-- 盒子 -->
        <div class="border_parent">
            <el-row :gutter="10">
                <el-col :xs="12" :sm="12" :md="6">
                    <div class="border">
                        <div class="border_img_parent">
                            <img src="../assets/icon/院内照护.png" class="img_border"/>
                        </div>
                        <div class="border_title">专属院内照护</div>
                        <div class="border_content_one" style="padding:0 30px;">为客户安排具有医疗资质的护理人员，为客户提供覆盖全国各省市合作医疗机构内住院专属护工服务、出院专业交通安排及陪护。</div>
                        <div class="border_content_two"></div>
                    </div>
                </el-col>
                <el-col :xs="12" :sm="12" :md="6">
                    <div class="border">
                        <div class="border_img_parent">
                            <img src="../assets/icon/康复护理.png" class="img_border"/>
                        </div>
                        <div class="border_title">居家康复护理</div>
                        <div class="border_content_one" style="padding:0 30px;">为客户安排具有医疗资质的护理人员，为客户提供覆盖全国各省市上门提供各类护理服务。包括：慢性病护理服务、长期护理服务、中医护理服务、院外康复服务等。</div>
                        <div class="border_content_two"></div>
                    </div>
                </el-col>
            </el-row>
        </div>
      </div>










      <el-collapse v-model="activeName" accordion v-if="!seen2">
        <el-collapse-item title="健康体检类" name="1">
          <div>
            <b>医疗机构体检</b>
          <p>联系医疗机构，设计科学的体检套餐，安排专业健康体检。</p>
          <b>体检报告解读</b>
          <p>根据需求联系医学专家提供专业的体检报告解读。</p>
          <b>基因检测</b>
          <p>
            为客户提供多种类型的基因检测产品和服务，包括高危肿瘤基因检测、HPV检测、结直肠癌早期筛查、重大慢性疾病检测、儿童生长发育基因检测、儿童天赋基因检测等。
          </p>
          <b>健康档案</b>
          <p>按照客户授权，建立电子健康档案，客户可在移动端随时查看档案。</p>
          <b>健康评估</b>
          <p>
            运用先进的评估软件，根据客户健康档案及问卷调查，评估健康风险。包括：AI中医体质测评、癌症风险自查、体姿体态评估、亚健康评估等多种评估。
          </p>
          </div>
          <div>
            在界面中一致：所有的元素和结构需保持一致，比如：设计样式、图标和文本、元素的位置等。
          </div>
        </el-collapse-item>
        <el-collapse-item title="健康咨询类" name="2">
          <div>
            <b>在线问诊</b>
          <p>
            专业医生在线快速解答健康问题，包括图文、视频等多种形式，小病小痛线上随时咨询，客户的家庭医生，身边的健康助手。
          </p>
          <b>电话医生</b>
          <p>
            为客户精心打造全流程贴心、温暖、便捷的电话医生直通服务，提供常见病症咨询。
          </p>
          <b>心理咨询</b>
          <p>
            与专业心理咨询机构合作，可为客户提供7*24小时的电话心理咨询热线服务，专业心理咨询师为客户一对一解答各类心理问题，包括工作压力、情绪困扰、精神健康、人际关系、职业发展、恋爱、婚姻、亲子教育、家庭关系等。
          </p>
          </div>
        </el-collapse-item>
        <el-collapse-item title="健康促进类" name="3">
          <div>
            <b>健康讲座</b>
          <p>
            为关爱客户身心健康，进一步增强健康意识，提高身体素质，聘请知名医学专家、公共卫生专家、营养专家等健康领域专家，定期组织健康知识讲座。
          </p>
          <b>健康资讯</b>
          <p>
            通过多种渠道，多角度、全方位向客户传播权威健康资讯，包括：名医讲堂、健康视频、健康资讯、健康百科。
          </p>
          <b>健康数据监测</b>
          <p>
            通过穿戴式智能设备进行全面的健康风险筛查；对健康指标异常客户进行分级分类管理，开展定期指标监测和健康指导，如身高、体重、体脂含量、心率、血压、心率变异性、血糖、血脂、血氧、尿酸、心电等监测服务。
          </p>
          <b>中医特色服务</b>
          <p>
            专业的中医理疗师使用手法推拿、拔罐、刮痧及仪器理疗，缓解肩颈、腰椎以及其他部位身体不适，达到放松和治疗效果。
          </p>
          </div>
        </el-collapse-item>
        <el-collapse-item title="疾病预防类" name="4">
          <div>
            <b>齿科服务</b>
          <p>
            利用知名的齿科机构资源，为客户搭建方便、快捷的齿科保健服务平台，提供多种类型的齿科服务及套餐，服务网络遍布全国，协助客户合理选择齿科服务和齿科门店。
          </p>
          <b>眼科服务</b>
          <p>
            利用知名的眼科机构资源，为客户搭建方便、快捷的眼部保健服务平台，提供丰富、实用的眼科服务，包括：干眼症治疗体验套餐、青少年近视防控检查、糖尿病视网膜病变管理检查套餐等。服务网络覆盖全国绝大部分省市，协助客户合理选择眼科服务和门店。
          </p>
          <b>中医服务</b>
          <p>
            发挥中医治未病优势，利用全国中医治未病科、中医门诊部/诊所资源，为客户搭建方便、快捷的中医治未病保健服务平台，服务网络遍布全国，协助客户合理选择中医服务和门店。
          </p>
          <b>疫苗接种服务</b>
          <p>协调安排社区卫生服务中心、民营医疗机构等为客户接种各类疫苗。</p>
          </div>
        </el-collapse-item>
        <el-collapse-item title="慢病管理类" name="5">
          <div>
            <b>健康干预服务</b>
          <p>
            评估客户的阶段性健康监测指标，分析评估慢性病风险，针对性的开展饮食、运动及健康生活方式指导，进行个性化的健康干预及慢性病管理。
          </p>
          <b>家庭医生服务</b>
          <p>
            由签约医学专家作为客户的家庭医生，为客户及家人提供全面体检管理、日常健康咨询、慢性疾病管理、定期健康随访等健康管理服务，为客户全家人的健康保驾护航。
          </p>
          </div>
        </el-collapse-item>
        <el-collapse-item title="就医服务类" name="6">
          <div>
            <b>门诊预约</b>
          <p>
            为客户提供合作医院的专家门诊预约服务（副主任医师及以上），可以指定医院、科室，不能指定专家。
          </p>
          <b>全程陪诊</b>
          <p>
            由专业陪诊人员提供全程陪同服务。陪诊人员带领客户至专家所在楼层、诊室，陪同等候，代客户完成检查化验排队、划价、取药、代取检查化验单、复印病历等事务，并提供相关健康信息。
          </p>
          <b>住院协助</b>
          <p>为客户提供住院联系安排，解决由于医疗信息不对称导致的住院难题。</p>
          <b>手术安排</b>
          <p>
            为客户提供手术预约安排，避免客户住院确诊之后长期无法手术的问题。
          </p>
          <b>二次诊疗意见</b>
          <p>
            提供合作医疗机构的专家第二诊疗意见服务，通过病理会诊、影像会诊等途径，由专家对客户的病情进行进一步分析，再次进行诊断或提出治疗意见。
          </p>
          <b>远程医疗咨询</b>
          <p>
            依托国内的公立三甲医院，通过高清远程问诊网络平台，运用高科技手段提供专业、客观、及时的远程咨询服务。
          </p>
          <b>多学科会诊MDT服务</b>
          <p>
            整合多学科团队的医学专家，通过信息科技手段进行实时沟通，为肿瘤患者及其他重大疾病患者进行协作会诊，讨论制定治疗方案。
          </p>
          <b>海外医疗服务</b>
          <p>
            根据客户的需求，帮助客户获取国际医疗资源，提供合作机构范围内专业、优质的海外健康体检套餐服务、海外医疗机构和医学专家诊疗服务、海外远程会诊服务等。
          </p>
          </div>
        </el-collapse-item>
        <el-collapse-item title="药品服务类" name="7">
          <div>
            <b>线上购药</b>
          <p>
            利用线上购药服务网络资源，提供合作连锁药店在线购药服务。连锁品牌药店，质量有保障，药品齐全，核心城区28分钟送药到家。
          </p>
          <b>线下药店购药</b>
          <p>
            客户可在我公司合作的线下药店购药，使用健康服务卡支付。提供附近药店查找及详细地址，支持提前电话咨询。
          </p>
          <b>代购药品</b>
          <p>为患有慢性疾病需要长期规律用药的客户，提供药品代购服务。</p>
          </div>
        </el-collapse-item>
        <el-collapse-item title="康复护理类" name="8">
          <div>
            <b>专属院内照护</b>
          <p>
            为客户安排具有医疗资质的护理人员，为客户提供覆盖全国各省市合作医疗机构内住院专属护工服务、出院专业交通安排及陪护。
          </p>
          <b>居家康复护理</b>
          <p>
            为客户安排具有医疗资质的护理人员，为客户提供覆盖全国各省市上门提供各类护理服务。包括：慢性病护理服务、长期护理服务、中医护理服务、院外康复服务等。
          </p>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
    <div style="width: 100%; height:100px;"></div>
    <Footer></Footer>
  </div>
</template>
<script>
import Header from "./Layout";
import Footer from "./footer";
export default {
  components: { Header, Footer },
  data() {
    return {
      tabPosition: "left",
      activeName: "1",
      seen2:true
    };
  },
  methods:{
    menuSeen() {
        // 屏幕尺寸
        let screenHeight = document.body.clientWidth;
        if (screenHeight > 600) {
          this.seen2 = true;
        } else {
          this.seen2 = false;
        }
      },
  },
  mounted(){
    this.menuSeen()
  },
  computed: {
        Src_() {
            let w = document.body.clientWidth;
            if (w > 600) {
                return require('../assets/cp.png')
            } else {
                return require('../assets/cp_2.png')
            }
        },
    }
};
</script>
<style scoped>
@media screen and (min-width: 800px) {
    .banner{
        height: auto !important;
        margin-bottom: 0 !important;
    }
}
@media screen and (max-device-width: 600px) {
    .product_pc::v-deep .el-row{
        display: flex;
        align-items: center;
        flex-direction: column;
    }
    .banner{
      height: auto !important;
    }
  }
.main {
  width: 100%;
  /* height: 700px; */
  /* background: orange; */
  /* border: 1px solid red; */
}
.el-collapse{
    margin: 0 20px;
}
.el-tabs {
  /* border: 1px solid red; */
  width: 50vw;
  margin: 0 auto;
  /* height: 500px; */
}
.el-tabs ::v-deep .el-tabs__item {
  font-size: 30px;
  height: 70px;
  line-height: 50px;
  font-weight: bolder;
}
.el-tabs ::v-deep .el-tabs__content {
  font-size: 20px;
  padding: 15px 0 0 15px;
}
.border_parent {
        width: 90%;
        margin: auto;
    }
    /* .border :hover {
        transform: scale(1.2);
        transition: .5s;
    } */
    .slider_img {
        height: 450px;
        width: 100%;
    }

    .solve {
        margin-top: 30px;
        margin-bottom: 10px
    }

    .border {
        border-radius: 6px;
        border: 1px solid #EBEEF5;
        height: auto;
        margin: 10px;
        font-size: 24px;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        height: 400px;
    }
    .ht{
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 50px;
        font-weight: bolder;
        color:white;
        background:#15aec4;
        width:100%; 
        height:150px;
        color:white;
    }
    .product_pc::v-deep .el-row{
        display: flex;
        align-items: center;
    }
 .ht img{
    max-width:100%;
    max-height:162px;
 }
    .border_img_parent {
        margin-top: 20px;
        display: flex;
        justify-content: center;
    }

    .product_mobile_content {
        height: 300px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .product_mobile_img {
        width: 50%;
    }

    .product_mobile_title {
        
        text-align: center;
        font-size: 25px;
    }

    .product_pc_one {
        /* border: 1px solid red; */
        height:100%;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .font_size_30 {
        font-size: 24px;
    }

    .product_font_style {
        font-size: 18px;
    }

    .product_pc_img {
        width: 100%;
        height: 100%;
        margin: 0 auto;
    }

    .product_pc_img_div {
        width: 50%;
        float: left;
        display: flex;
        align-items: center;
    }

    .product_pc {
        display: flex;
        width: 90%;
        margin: 20px auto;
        background: #F2F6FC;
        align-items: center;
    }
    .product_pc .el-row{
        width: 100%;
    }
    .product_spec_title {
        margin-top: 30px;
        margin-bottom: 10px
    }

    .product_spec_parent {
        display: flex;
        width: 90%;
        margin: 20px auto;
        align-items: center;
        height: auto
    }

    .cooperation_parent {
        display: flex;
        justify-content: center;
        width: 90%;
        margin: 20px auto;
        align-items: center;
        height: auto
    }

    .border_spec {
        border-radius: 6px;
        border: 1px solid #EBEEF5;
        float: left;
        width: 25%;
        height: 250px;
        margin: 10px;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    }

    .border_title {
        margin-top: 10px;
        font-size: 18px;
        
        text-align: center;
    }

    .border_content_one{
        margin-top: 20px;
        font-size: 16px;
        text-align: center;
        padding: 0 5px;
    }

    .border_content_two {
        margin-top: 5px;
        font-size: 16px;
        margin-bottom: 25px;
        text-align: center;
    }

    .img_border {
        margin-top: 15px;
        width: 80px;
        height: 80px
    }

    .border_spec_inner_one {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 35px;
        height: 70px;
    }

    .border_spec_content_one {
        margin-top: 15px;
        font-size: 20px;
        float: left;
        text-align: center;
    }

    .border_spec_inner_last {
        margin-top: 15px;
        font-size: 20px;
        float: left;
        margin-bottom: 25px;
        text-align: center;
    }

    .cooperation {
        margin-top: 30px;
        width: 100%;
    }

    .about_me {
        display: flex;
        margin-top: 30px;
        justify-content: center;
        width: 100%;
    }

    .product_mobile {
        width: 90%;
        align-items: center;
        margin: 20px auto;
        background: #F2F6FC;
    }

    .product_mobile_img_div {
        width: 50%;
        height: auto;
    }

    .product_mobile_content_one {
        margin-top: 20px;
        text-align: center;
    }

    .product_mobile_content_two {
        margin-top: 10px;
        text-align: center;
    }

    

    .border_spec_img {
        width: 75px;
        height: 55px;
        align-self: center;
    }

    .font_size_24 {
        font-size: 24px;
    }
</style>